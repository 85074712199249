// 密码格式，数字+小写字母+大写字母+特殊符号，8位以上，不包含空格
export const passwordRegExp =
  /^(?!.*\s)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]).{8,}$/;

// 校验password不包含password和test单词及其任意字母的大小写变体
export const validatePasswordExclusion = /^(?!.*\b(password|test)\b)/;

/**
 * @description: 校验密码格式是否正确
 * @params {
 *  password：密码，
 *  message：格式或者长度错误提示，
 * }
 * @return {Promise}
 */
export const passwordValidator: (password: string, message: string) => Promise<any> = (
  // any 不修改，promise不强制数据结构
  password,
  message
) => {
  const lowerCasePassword = password.toLowerCase();
  if (!password || !validatePasswordExclusion.test(lowerCasePassword) || !passwordRegExp.test(password)) {
    return Promise.reject(new Error(message));
  }
  return Promise.resolve();
};

/**
 * @description: 校验确认密码格式以及是否和密码一致，重置密码和设置密码使用
 * @params {
 *  confirmPassword：确认密码，
 *  password：密码，
 *  message：格式或者长度错误提示，
 *  notMatch：两次密码不一致时的提示
 * }
 * @return {Promise}
 */
export const passwordConfirmValidator: (
  confirmPassword: string,
  password: string,
  message: string,
  notMatch: string
) => Promise<any> = (
  // 不修改，promise不强制数据结构
  confirmPassword,
  password,
  message,
  notMatch
) => {
  if (!confirmPassword) {
    return Promise.reject(new Error(message));
  }
  if (password !== confirmPassword) {
    return Promise.reject(new Error(notMatch));
  }
  const lowerCaseConfirmPassword = confirmPassword.toLowerCase();
  // 校验password不包含password和test单词及其任意字母的大小写变体
  const regex = /^(?!.*\b(password|test)\b)/;
  if (
    !confirmPassword ||
    password.length !== confirmPassword.length ||
    !regex.test(lowerCaseConfirmPassword) ||
    !passwordRegExp.test(confirmPassword)
  ) {
    return Promise.reject(new Error(message));
  }
  return Promise.resolve();
};

/**
 * @description: 校验密码格式是否正确
 * @params {
 *  password：密码
 * }
 * @return {boolean}
 */
export const checkPassword: (password: string) => boolean = (password: string) => {
  const lowerCasePassword = password.toLowerCase();
  return !!password && passwordRegExp.test(password) && validatePasswordExclusion.test(lowerCasePassword);
};