import React, { memo, useState } from "react";
import { Form } from "antd";
import { message, SafetyTips, CommonLabelInput, ModalBase, EmailInputGroup } from "@aspen/ui";
import { resetPassword, resetManagerPassword, subResetPassword } from "@aspen/services";
import styles from "@aspen/theme/Profile.module.less";
import {
  reportEvent,
  i18nUtil,
  PASSWORD_MAX_LENGTH,
  USER_ROLE,
  USER_AUTH,
  GA_EVENT_NAME,
  GA_EVENT_TAG,
  USER,
  LOGIN_PATHS,
  md5Encrypted,
  MD5_FIXED_STRING
} from "@aspen/libs";
import clsx from "clsx";
import { IBindingBusinessType } from "@aspen/model";
import { passwordConfirmValidator, passwordValidator } from "@adgm/libs";

interface IProps {
  handleCancel: () => void;
  onOk?: () => void;
  visible: boolean;
}
export const ModalChangePassword = memo((props: IProps) => {
  const { handleCancel, visible, onOk } = props;
  const user_auth = typeof window != "undefined" && localStorage.getItem(USER_AUTH);

  const [form] = Form.useForm();
  const [oldPassword, setOldPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [emailCode, setEmailCode] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const intl = i18nUtil.t();

  const handleOk = async () => {
    try {
      await form.validateFields();
      if (newPassword == oldPassword) {
        message.error(intl["different.password"]);
        return;
      }

      const params = {
        oldPassword: md5Encrypted(oldPassword, MD5_FIXED_STRING),
        newPassword: md5Encrypted(newPassword, MD5_FIXED_STRING),
        emailCode
      };
      setLoading(true);

      let method;
      switch (user_auth) {
        case USER_ROLE.ROLE_MANAGER:
          method = resetManagerPassword;
          break;
        case USER_ROLE.ROLE_SUB:
          method = subResetPassword;
          break;
        default:
          method = resetPassword;
          break;
      }

      method(params)
        .then((res) => {
          if (res?.code == "0") {
            if (onOk !== undefined) {
              onOk();
            } else {
              message.success(intl["modal.changePassword.success"]);
              handleCancel();
              window.location.href = LOGIN_PATHS.LOGIN;
            }
          } else {
            message.error(intl?.[res?.msg] ?? res?.msg);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (errorInfo) {
      console.info("Failed:", errorInfo);
    }

    reportEvent({
      moduleName: GA_EVENT_NAME.profile.security.changePwd,
      joinedTag: GA_EVENT_TAG.Click
    });
  };

  const getText: () => { [propName: string]: any } = () => {
    // 不修改
    const okText: string = intl["button.next"];
    const cancelText: string = intl["button.cancel"];
    const title: string = intl["profile.basic.change.password"];
    const newPasswordLabel: string = intl["new.password"];
    const oldPasswordLabel: string = intl["old.password"];
    const confirmPassword: string = intl["confirm.password"];
    const messagePassword: string = intl["password.format.tip"];
    const passwordRequired: string = intl["customer.password.required"];
    const notMatch: string = intl["password.not.match"];
    return {
      confirmPassword,
      okText,
      title,
      cancelText,
      oldPasswordLabel,
      passwordRequired,
      newPasswordLabel,
      messagePassword,
      notMatch
    };
  };

  const {
    confirmPassword,
    okText,
    title,
    oldPasswordLabel,
    passwordRequired,
    newPasswordLabel,
    messagePassword,
    notMatch
  } = getText();

  return (
    <ModalBase
      maskClosable={false}
      buttonType="only"
      wrapClassName={clsx(styles.modal, styles.modalBottom)}
      title={title}
      open={visible}
      confirmLoading={loading}
      onOk={() => void handleOk()}
      centered
      okText={okText}
      destroyOnClose
      width={480}
      onCancel={handleCancel}>
      <>
        {user_auth !== USER_ROLE.ROLE_MANAGER && (
          // @ts-ignore
          <SafetyTips description={intl["reset.password.tip"]} />
        )}

        <Form autoComplete="off" layout="vertical" preserve={false} form={form}>
          {/* 防止输入框自动填充 */}
          <div className="hiddenInput">
            <input className="hiddenInput" />
            <input type="password" className="hiddenInput" />
          </div>
          {user_auth !== USER_ROLE.ROLE_MANAGER && (
            <Form.Item noStyle name="emailCode">
              <EmailInputGroup
                // @ts-ignore
                businessType={IBindingBusinessType.resetPassword}
                label={intl["login.email.verify.code"]}
                onChangeTxt={(code) => {
                  setEmailCode(code);
                }}
                email={user_auth === USER_ROLE.ROLE_SUB ? sessionStorage.getItem(USER) : undefined}
              />
            </Form.Item>
          )}
          <Form.Item name="password" rules={[{ required: true, message: passwordRequired }]}>
            <CommonLabelInput
              isTop={!!oldPassword}
              label={oldPasswordLabel}
              type="password"
              maxLength={PASSWORD_MAX_LENGTH}
              onChange={(e) => setOldPassword(e.target.value)}
            />
          </Form.Item>
          <Form.Item
            name="newPassword"
            rules={[
              () => ({
                validator(_, value) {
                  return passwordValidator(value, messagePassword);
                }
              })
            ]}>
            <CommonLabelInput
              isTop={!!newPassword}
              label={newPasswordLabel}
              type="password"
              maxLength={PASSWORD_MAX_LENGTH}
              onChange={(e) => setNewPassword(e.target.value)}
              autoComplete="off"
            />
          </Form.Item>
          {/* 防止自动填充密码 */}
          {/* <Input type="text"  className="hiddenInput" /> */}
          <Form.Item
            dependencies={["newPassword"]}
            name="passwordconfirm"
            rules={[
              () => ({
                validator(_, value) {
                  return passwordConfirmValidator(value, newPassword, messagePassword, notMatch);
                }
              })
            ]}>
            <CommonLabelInput
              label={confirmPassword}
              type="password"
              maxLength={PASSWORD_MAX_LENGTH}
              autoComplete="off"
            />
          </Form.Item>
        </Form>
      </>
    </ModalBase>
  );
});
