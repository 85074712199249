import { ShapeAttrs } from "@antv/g-base";
import { IMessageToApp } from "@aspen/model/index";

/**
 * 角度转换成弧度
 * @params number 角度
 * @return number 处理后的数值
 *
 */
const degree2Angle: (angle: number) => number = (angle) => {
  return (angle / 180) * Math.PI;
};

/**
 * 总资产字号
 * @param length
 */
const getTotalAssetsFontSize = (length: number) => {
  if (length < 10) {
    return 30;
  } else if (10 <= length && length < 15) {
    return 22;
  } else if (15 <= length && length < 19) {
    return 18;
  } else {
    return 16;
  }
};

const appLineChartStyle: {
  xLabelStyle?: ShapeAttrs;
  yLabelStyle?: ShapeAttrs;
  yGridLineStyle?: ShapeAttrs;
  lineWidth?: number;
  yAxisPosition?: "top" | "bottom" | "right" | "left";
  yTickCount?: number;
} = {
  lineWidth: 1,
  yAxisPosition: "right",
  yTickCount: 6,
  xLabelStyle: {
    fontSize: 9,
    lineHeight: 10,
    fill: "#ffffff",
    fillOpacity: 0.69,
    fontWeight:400,
  },
  yLabelStyle: {
    fontSize: 8,
    lineHeight: 10,
    fill: "#ffffff",
    fillOpacity: 0.7,
    fontWeight:400,
  },
  yGridLineStyle: {
    stroke: "#ffffff",
    lineWidth: 0.9,
    lineDash: [2, 2],
    strokeOpacity: 0.1,
    fontWeight:400,
  }
};

const sendToApp = (res: IMessageToApp) => {
  // @ts-ignore
  window?.ReactNativeWebView?.postMessage(JSON.stringify(res));
};

/**
 * @description: 获取收益文字颜色
 * @params number
 * @return string
 */
const getPNLColor: (params: number | string | unknown) => string = (params) => {
  if (params === null) {
    return "#fff";
  }
  const value = Number(params);
  if (value === 0) {
    return "#F17E00";
  } else if (value > 0) {
    return "#09D687";
  } else if (value < 0) {
    return "#DA4931";
  }
  return "#F17E00";
};

export { degree2Angle, getTotalAssetsFontSize, appLineChartStyle, sendToApp, getPNLColor };
